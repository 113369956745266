import { useMutation, useSuspenseQuery } from '@apollo/client';
import React from 'react';
import {
  Alert,
  Col,
  Flex,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import {
  formatIban,
  formatSortCode,
  fromCents,
  parseMoney,
  validCountries,
} from '@guuru/money-common';
import {
  ConditionalFields,
  Field,
  Form,
  useForm,
  useWatch,
} from '@guuru/react-forms';
import { error } from '@guuru/react-message';
import { dac7 } from '@guuru/countries-common';
import UPDATE_ACCOUNT from './mutations/updateAccount';
import GET_EXPERT_ACCOUNT from './queries/getExpertAccount';
import useCurrentUser from '../../../hooks/useCurrentUser';

const formatAmount = (amount, { input, userTyping } = {}) => (
  userTyping ? input : fromCents(amount).toFixed(2)
);

const onInvalidFields = (invalidFields) => (
  error(`Failed to update, ${invalidFields.length} invalid fields`, false)
);

const PaymentSettings = function () {
  const { id: expertId } = useCurrentUser();
  const [form] = useForm();
  const settlementCurrency = useWatch('settlementCurrency', form);

  const [
    updateExpertAccount,
    { loading: updating },
  ] = useMutation(UPDATE_ACCOUNT);

  const {
    data: {
      expert: {
        account = {},
      } = {},
    } = {},
  } = useSuspenseQuery(GET_EXPERT_ACCOUNT, {
    variables: {
      id: expertId,
    },
  });

  const onFinish = (values) => (
    updateExpertAccount({
      variables: {
        id: account.id,
        input: values,
      },
    })
  );

  const handleSortCodeChange = (e) => {
    const { value } = e.target;
    return formatSortCode(value);
  };

  const handleIbanChange = (e) => {
    const { value } = e.target;
    return formatIban(value);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Alert
          showIcon
          message={(
            <>
              {'Transfer fees might apply: learn more about our '}
              <a
                type="link"
                target="_blank"
                href="https://www.guuru.com/en/payment-policy/"
                rel="noreferrer"
              >
                payment policy
              </a>
              .
            </>
          )}
          type="info"
        />
      </Col>
      <Col span={24}>
        <Form
          form={form}
          onSave={onFinish}
          onInvalidFields={onInvalidFields}
          isSaving={updating}
          saveLabel="Update"
          savingMessage="Updating bank account details..."
          savedMessage="Bank account details updated"
          initialValues={{
            ...account,
            iban: formatIban(account.iban),
            sortCode: formatSortCode(account.sortCode),
          }}
        >
          <Field
            name="settlementCurrency"
            label="Settlement currency"
            required
          >
            <Radio.Group>
              <Flex vertical>
                <Radio value="CHF">CHF - Swiss Franc</Radio>
                <Radio value="EUR">EUR - Euro</Radio>
                <Radio value="GBP">GBP - Pound Sterling</Radio>
                <Radio value="USD">USD - United States Dollar</Radio>
                <Radio value="CAD">CAD - Canadian Dollar</Radio>
              </Flex>
            </Radio.Group>
          </Field>

          <Field
            name="minPayableAmount"
            label="Minimum Payable Amount"
          >
            <InputNumber
              addonBefore={settlementCurrency}
              formatter={formatAmount}
              parser={parseMoney}
            />
          </Field>

          <ConditionalFields
            field="settlementCurrency"
            visible={(currency) => !currency || ['EUR', 'CHF'].includes(currency)}
          >
            <Field
              label="IBAN"
              name="iban"
              getValueFromEvent={handleIbanChange}
              required
            >
              <Input maxLength={42} />
            </Field>
            <Field label="BIC/SWIFT" name="bic" required>
              <Input />
            </Field>
          </ConditionalFields>

          <ConditionalFields
            field="settlementCurrency"
            visible={(currency) => currency === 'GBP'}
          >
            <Field label="Account Number" name="accountNo" required>
              <Input maxLength={34} />
            </Field>
            <Field
              label="Sort Code"
              name="sortCode"
              getValueFromEvent={handleSortCodeChange}
              required
            >
              <Input maxLength={8} />
            </Field>
          </ConditionalFields>

          <ConditionalFields
            field="settlementCurrency"
            visible={(currency) => currency === 'USD'}
          >
            <Field label="Account Number" name="accountNo" required>
              <Input maxLength={17} />
            </Field>
            <Field label="Routing Number" name="routingNumber" required>
              <Input maxLength={9} />
            </Field>
          </ConditionalFields>

          <ConditionalFields
            field="settlementCurrency"
            visible={(currency) => currency === 'CAD'}
          >
            <Field label="Account Number" name="accountNo" required>
              <Input maxLength={17} />
            </Field>
            <Field label="BIC/SWIFT" name="bic" required>
              <Input />
            </Field>
          </ConditionalFields>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Field label="Name" name="firstName" required>
                <Input />
              </Field>
            </Col>
            <Col xs={24} sm={12}>
              <Field label="Surname" name="lastName" required>
                <Input />
              </Field>
            </Col>
          </Row>
          <Field label="Address (street and number)" name="address" required>
            <Input />
          </Field>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <Field label="Country" name="country" required>
                <Select showSearch>
                  {Object.keys(validCountries).map((countryKey) => (
                    <Select.Option key={countryKey}>
                      <Flex align="center">
                        {countryKey}
                      </Flex>
                    </Select.Option>
                  ))}
                </Select>
              </Field>
            </Col>
            <Col xs={24} sm={16}>
              <Field label="Zip-code" name="postalCode" required>
                <Input />
              </Field>
            </Col>
          </Row>
          <Field label="City/Town" name="city" required>
            <Input />
          </Field>

          <ConditionalFields
            field="settlementCurrency"
            visible={(currency) => currency === 'USD'}
          >
            <Field label="State" name="province" required>
              <Input />
            </Field>
          </ConditionalFields>

          <ConditionalFields
            field="settlementCurrency"
            visible={(currency) => currency === 'CAD'}
          >
            <Field label="Province" name="province" required>
              <Input />
            </Field>
          </ConditionalFields>

          <ConditionalFields
            field="country"
            visible={(country) => dac7.includes(country)}
          >
            <Field
              label="Tax Identification Number (TIN)"
              name="tin"
              required
              extra={(
                <small>
                  <a
                    href="https://www.guuru.com/en/community-standards/#tin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </small>
              )}
            >
              <Input />
            </Field>
            <Field label="VAT Number (if available)" name="vat">
              <Input />
            </Field>
          </ConditionalFields>
        </Form>
      </Col>
    </Row>
  );
};

export default PaymentSettings;
