import React, { useMemo } from 'react';
import { Table } from 'antd';
import Balance from '@guuru/react-balance';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { PayoutTable } from './style';

const sum = (balances) => balances.reduce((acc, { amount }) => acc + amount, 0);
const cmp = (...items) => {
  const [a, b] = items.map((item) => {
    const [code] = item.split(' ');
    return code;
  });
  return a.localeCompare(b);
};

const productName = (item) => {
  if (!item?.product) {
    return '';
  }

  const { product, status, balances } = item;
  if (!['payments', 'payments delayed'].includes(product)) {
    return startCase(product);
  }

  if (product === 'payments' && sum(balances) > 0) {
    return 'Refunded';
  }

  if (product === 'payments') {
    return 'Paid out';
  }

  if (status === 'delayed') {
    return 'Carried forward to next month';
  }

  return 'Carried over from previous months';
};

const Payout = function ({ statement = {} }) {
  const statements = useMemo(() => (
    [...statement?.node?.statements ?? []].sort((a, b) => (
      cmp(a.product, b.product) || sum(b.balances) - sum(a.balances)
    ))
  ), [statement?.node?.statements]);

  return (
    <PayoutTable
      rowKey="index"
      dataSource={statements}
      pagination={false}
      size="small"
    >
      <Table.Column
        title="Product"
        dataIndex="product"
        key="product"
        render={(text, node) => productName(node)}
      />
      <Table.Column
        title="Balance"
        align="right"
        dataIndex="balances"
        key="balances"
        ellipsis
        render={(text, node) => (
          <Balance balances={node?.balances} />
        )}
      />
    </PayoutTable>
  );
};

Payout.propTypes = {
  statement: PropTypes.shape({
    node: PropTypes.shape({
      period: PropTypes.number,
      statements: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default Payout;
